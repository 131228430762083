import RouterHolder from '@/components/router-holder'

import formatRoutesByEnhanceMeta from './helpers/formatRoutesByEnhanceMeta'
/**
 * @meta
 * title: 菜单名称
 * icon: 菜单显示图标
 * hideSubmenu: children 配置不显示在子菜单中
 * hideMenu: 当前配置不显示在菜单中
 */
const main = [
  {
    path: 'banner',
    name: 'banner',
    meta: { title: '首页Banner', icon: '&#xe623;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/banner',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () =>
          import(/* webpackChunkName: "banner" */ '@/views/banner')
      },
      {
        path: 'add',
        meta: { title: '添加Banner' },
        component: () =>
          import(/* webpackChunkName: "banner" */ '@/views/banner/add')
      },
      {
        path: 'update/:id',
        meta: { title: '修改Banner' },
        component: () =>
          import(/* webpackChunkName: "banner" */ '@/views/banner/add')
      }
    ]
  },
  {
    path: 'news',
    name: 'news',
    meta: { title: '新闻资讯', icon: '&#xe65f;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/news/list',
    children: [
      {
        path: 'list',
        meta: { title: '列表' },
        component: () => import(/* webpackChunkName: "news" */ '@/views/news')
      },
      {
        path: 'add',
        meta: { title: '添加新闻' },
        component: () =>
          import(/* webpackChunkName: "news" */ '@/views/news/add')
      },
      {
        path: 'update/:id',
        meta: { title: '修改新闻' },
        component: () =>
          import(/* webpackChunkName: "news" */ '@/views/news/add')
      }
    ]
  },
  {
    path: 'case',
    name: 'case',
    meta: { title: '历届作品', icon: '&#xe615;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/case/list',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () => import(/* webpackChunkName: "case" */ '@/views/case')
      },
      {
        path: 'add',
        meta: { title: '添加作品' },
        component: () =>
          import(/* webpackChunkName: "case" */ '@/views/case/add')
      },
      {
        path: 'update/:id',
        meta: { title: '修改作品' },
        component: () =>
          import(/* webpackChunkName: "case" */ '@/views/case/add')
      }
    ]
  },
  {
    path: 'works',
    name: 'works',
    meta: { title: '作品', icon: '&#xe605;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/works/list',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () => import(/* webpackChunkName: "works" */ '@/views/works')
      }
    ]
  },
  {
    path: 'contestant',
    name: 'contestant',
    meta: { title: '参赛用户', icon: '&#xe65a;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/contestant/list',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () =>
          import(/* webpackChunkName: "contestant" */ '@/views/contestant')
      }
    ]
  }
]

export default formatRoutesByEnhanceMeta(main)
