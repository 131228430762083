const apis = [
  {
    fn: 'baseparamList',
    method: 'post',
    url: '/gwBaseParam/getPageList',
    hideLoading: true
  },
  {
    fn: 'baseparamAdd',
    method: 'post',
    url: '/gwBaseParam/add'
  },
  {
    fn: 'addCompanyLogo',
    method: 'post',
    url: '/gwBaseParam/addCompanyLogo'
  },
  {
    fn: 'addService',
    method: 'post',
    url: '/gwBaseParam/addService'
  },
  {
    fn: 'baseparamUpdate',
    method: 'post',
    url: '/gwBaseParam/update'
  },
  {
    fn: 'baseparamInfo',
    method: 'get',
    url: '/gwBaseParam/info',
    direct: true
  },
  {
    fn: 'baseparamDelete',
    method: 'post',
    url: '/gwBaseParam/delete',
    direct: true
  }
]

export default apis
