import axios from 'axios'
import { Loading, Message } from 'element-ui'
import localStore from 'store'
import store from '@/store'
import { baseUrl } from '@/config/env'
import { getValidParam } from '@/utils/tools'

export const FAIL = Symbol('fail')
export const SUCCESS = 200 // 登录成功
export const TIMEOUT = 70001010 // 尚未登录或登录超时，请登录后使用

axios.interceptors.request.use(
  function (config) {
    if (
      config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      const formData = new FormData()
      for (let k in config.data || {}) {
        if (Object.prototype.hasOwnProperty.call(config.data, k)) {
          formData.append(k, config.data[k])
        }
      }
      config.data = formData
    }
    const user = localStore.get('feisi-user2')
    if (user?.token) {
      config.headers['token'] = user.token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

const ajax = async ({
  headers,
  method,
  url,
  data,
  params,
  restConfig = {},
  blob = false, // 是否二级制直传
  error,
  cash = false,
  isNeedEmpty,
  hideLoading,
  loadingTxt = ''
}) => {
  let loadingInstance
  if (!hideLoading) {
    loadingInstance = Loading.service({ fullscreen: true, text: loadingTxt })
  }
  try {
    let config = {
      method,
      url: cash ? url + '?' + +new Date() : baseUrl + url,
      headers,
      data: getValidParam(data, isNeedEmpty),
      params: getValidParam(params, isNeedEmpty),
      ...restConfig
    }
    const { data: res } = await axios(config)

    loadingInstance && loadingInstance.close()
    if (cash || blob) return res

    if (res.code === TIMEOUT) {
      Message.error(res.message)
      await store.commit('initialize')
      return FAIL
    }

    if (res.code === SUCCESS) {
      // 成功返回
      return res.data === undefined ? res : res.data
    }

    if (error) {
      await error(res)
    } else {
      throw new Error(res.message)
    }

    return FAIL
  } catch (err) {
    loadingInstance && loadingInstance.close()
    Message.error(err.message)
    error &&
      error({
        message: err.message || 'Sorry, 网络发生了错误~'
      })
    return FAIL
  }
}

export default ajax
