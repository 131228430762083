const apis = [
  {
    fn: 'caseList',
    method: 'post',
    url: '/preWork/getPageList',
    hideLoading: true
  },
  {
    fn: 'caseAdd',
    method: 'post',
    url: '/preWork/add'
  },
  {
    fn: 'caseUpdate',
    method: 'post',
    url: '/preWork/update'
  },
  {
    fn: 'caseInfo',
    method: 'get',
    url: '/preWork/info',
    direct: true
  },
  {
    fn: 'caseDelete',
    method: 'post',
    url: '/preWork/delete',
    direct: true
  }
]

export default apis
