const isProduction = process.env.NODE_ENV === 'production'

let baseUrl = window.location.origin

if (isProduction) {
  baseUrl = 'http://api.yhmwdesign.com/api'
} else {
  baseUrl = 'http://api.yhmwdesign.com/api'
}

export { baseUrl }
