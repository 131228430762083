const list = [
  {
    label: '上',
    value: 'top'
  },
  {
    label: '中',
    value: 'center'
  },
  {
    label: '下',
    value: 'buttom'
  }
]

export default list
