const apis = [
  {
    fn: 'contestantList',
    method: 'post',
    url: '/user/getPageList',
    hideLoading: true
  }
]

export default apis
