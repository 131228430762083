import LayoutBasis from '@/layout/basis'
import LayoutUser from '@/layout/user'
import main from './main'

const routes = [
  {
    path: '/',
    component: LayoutBasis,
    name: 'LayoutBasis',
    redirect: '/banner',
    children: main
  },
  {
    path: '/user',
    component: LayoutUser,
    name: 'LayoutUser',
    redirect: '/user/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () =>
          import(/* webpackChunkName: "user" */ '@/views/user/login'),
        meta: {
          title: '用户登录'
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

export default routes
