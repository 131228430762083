<script>
export default {
  name: 'TreeMenu',
  functional: true,
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  render(h, { props }) {
    const { data } = props
    return data.map((menu) => {
      const meta = menu.meta
      if (menu.children && !meta.hideSubmenu) {
        return (
          <el-submenu key={meta.realPath} index={meta.realPath}>
            <template slot="title">
              {meta.icon && (
                <i class="iconfont" domPropsInnerHTML={meta.icon}></i>
              )}
              <span>{meta.title || meta.realPath}</span>
            </template>
            <tree-menu data={menu.children}></tree-menu>
          </el-submenu>
        )
      } else if (!meta.hideMenu) {
        return (
          <el-menu-item key={meta.realPath} index={meta.realPath}>
            {meta.icon && (
              <i class="iconfont" domPropsInnerHTML={meta.icon}></i>
            )}
            <span>{meta.title || meta.realPath}</span>
          </el-menu-item>
        )
      }
      return null
    })
  }
}
</script>

<style scoped lang="scss">
.iconfont {
  margin-right: 6px;
  font-size: 18px;
  vertical-align: -3px;
}
</style>
