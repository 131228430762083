import { Message } from 'element-ui'
import localStore from 'store'
import routes from '@/router/main'
import router from '@/router'
import serve from '@/serve'

const common = {
  state: {
    routes,
    currentRoute: null,
    user: localStore.get('feisi-user2')
  },
  mutations: {
    currentRouteUpdate(state, payload) {
      state.currentRoute = payload
    },
    updateUser(state, payload) {
      state.user = payload
    },
    ossTokenUpdate(state, payload) {
      state.user = payload
    },
    initialize(state) {
      localStore.clearAll()
      state.user = null
      router.replace('/user/login')
    }
  },
  actions: {
    async login({ commit }, data) {
      const res = await serve.login({
        data
      })
      if (res === serve.FAIL) {
        return
      }
      localStore.set('feisi-user2', res)
      router.push('/')
      Message.success('登录成功')
      commit('updateUser', res)
    },
    async logout({ commit }) {
      const res = await serve.logout()
      if (res === serve.FAIL) {
        return
      }
      Message.success('退出成功')
      commit('initialize')
    }
  }
}

export default common
