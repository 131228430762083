const apis = [
  {
    fn: 'worksList',
    method: 'post',
    url: '/works/getPageList',
    hideLoading: true
  },
  {
    fn: 'exportWorks',
    method: 'get',
    url: '/works/exportWorks',
    blob: true,
    loadingTxt: '正在导出, 请稍后...',
    restConfig: {
      responseType: 'blob'
    }
  },
  {
    fn: 'exportWorksPic',
    method: 'get',
    url: '/works/exportWorksPic',
    blob: true,
    loadingTxt: '正在导出, 请稍后...',
    restConfig: {
      responseType: 'blob'
    }
  }
]

export default apis
