<template>
  <div class="layout__sider">
    <router-link class="logo" to="/">
      <!-- <img class="yx__abscenter" src="@/assets/img/logo.png" alt="飞思" /> -->
      木 玩
      <br />
      大 赛
    </router-link>
    <el-menu
      background-color="#464e63"
      active-text-color="#409EFF"
      text-color="#fff"
      router
      :default-active="active"
      class="sider-layout--menu"
    >
      <tree-menu :data="data"></tree-menu>
    </el-menu>
  </div>
</template>

<script>
import TreeMenu from '@/components/tree-menu'

export default {
  name: 'LayoutSider',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    currentRoute: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TreeMenu
  },
  computed: {
    active() {
      if (this.currentRoute.path === '/') {
        return ''
      }
      const matched = this.currentRoute.matched
      /**
       * 在子菜单隐藏或者菜单隐藏的时候需要筛选菜单匹配
       */
      const menuMatched = matched.filter((menu) => {
        if (menu.parent?.meta?.hideSubmenu || menu.meta?.hideMenu) {
          return false
        }
        return true
      })
      return menuMatched[menuMatched.length - 1]?.meta.realPath
    }
  }
}
</script>

<style scoped lang="scss">
.layout__sider {
  height: 100vh;
  overflow-y: auto;
  // background: linear-gradient(135deg, #0575e6 0%, #021b79 100%);
  background-color: #464e63;

  .logo {
    display: block;
    position: relative;
    width: 120px;
    height: 80px;
    line-height: 40px;
    transition: $all-transition;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    text-decoration: none;
    font-size: 32px;
    img {
      max-width: 100%;
    }
  }

  &--menu {
    border-right: none;
  }
}
</style>
