<template>
  <div class="global-footer">
    <p class="links">
      <a v-for="(item, index) in links" :href="item.href" :key="index">
        {{ item.title }}
      </a>
    </p>
    <p class="copyright">Copyright 2020 飞思技术部出品</p>
  </div>
</template>

<script>
export default {
  name: 'GlobalFooter',
  data() {
    return {
      links: [
        {
          title: '首页',
          href: '/'
        },
        {
          title: '飞思直播',
          href: '/'
        },
        {
          title: '飞思saas',
          href: '/'
        },
        {
          title: '木玩大赛',
          href: '/'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.global-footer {
  height: 100%;
  text-align: center;

  p {
    margin: 0;
    line-height: 30px;
  }

  .links {
    a {
      text-decoration: none;
      color: $color-primary;

      &:not(:last-child) {
        margin-right: 36px;
      }
    }
  }

  .copyright {
    font-size: 12px;
    color: #666;
  }
}
</style>
