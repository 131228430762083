const apis = [
  {
    fn: 'login',
    method: 'post',
    url: '/login'
  },
  {
    fn: 'logout',
    method: 'post',
    url: '/logout'
  }
]

export default apis
