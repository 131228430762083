const list = [
  {
    label: '不跳转',
    value: '0'
  },
  {
    label: '跳转新闻资讯',
    value: '1'
  },
  {
    label: '跳转历届作品',
    value: '2'
  },
  {
    label: '作品',
    value: '3'
  }
]

export default list
