const apis = [
  {
    fn: 'typeList',
    method: 'post',
    url: '/gwIndustry/getPageList',
    hideLoading: true
  },
  {
    fn: 'typeAdd',
    method: 'post',
    url: '/gwIndustry/add'
  },
  {
    fn: 'typeUpdate',
    method: 'post',
    url: '/gwIndustry/update'
  },
  {
    fn: 'typeInfo',
    method: 'get',
    url: '/gwIndustry/info',
    direct: true
  },
  {
    fn: 'typeDelete',
    method: 'post',
    url: '/gwIndustry/delete',
    direct: true
  }
]

export default apis
