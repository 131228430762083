const apis = [
  {
    fn: 'bannerList',
    method: 'post',
    url: '/banner/getPageList',
    hideLoading: true
  },
  {
    fn: 'bannerAdd',
    method: 'post',
    url: '/banner/add'
  },
  {
    fn: 'bannerUpdate',
    method: 'post',
    url: '/banner/update'
  },
  {
    fn: 'bannerInfo',
    method: 'get',
    url: '/banner/info',
    direct: true
  },
  {
    fn: 'bannerDelete',
    method: 'post',
    url: '/banner/delete',
    direct: true
  }
]

export default apis
