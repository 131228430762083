const list = [
  {
    label: '首页',
    value: '1'
  },
  {
    label: '新闻资讯',
    value: '2'
  },
  {
    label: '历届作品',
    value: '3'
  }
]

export default list
