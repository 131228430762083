const apis = [
  {
    fn: 'newsList',
    method: 'post',
    url: '/newInfo/getPageList',
    hideLoading: true
  },
  {
    fn: 'newsAdd',
    method: 'post',
    url: '/newInfo/add'
  },
  {
    fn: 'newsUpdate',
    method: 'post',
    url: '/newInfo/update'
  },
  {
    fn: 'newsInfo',
    method: 'get',
    url: '/newInfo/info',
    direct: true
  },
  {
    fn: 'newsDelete',
    method: 'post',
    url: '/newInfo/delete',
    direct: true
  }
]

export default apis
