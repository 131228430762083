import Vue from 'vue'
import serve from '@/serve' // 结构请求服务
import formatTime from '@/filters/modules/_format-time'
import download from '@/utils/download'
import OssUploader from '@/utils/oss-uploader'
import enums, { enumsMap } from '@/enums'

Vue.prototype.$serve = serve
Vue.prototype.$formatTime = formatTime
Vue.prototype.$download = download
Vue.prototype.$enums = enums // 应用枚举列表
Vue.prototype.$enumsMap = enumsMap // 应用枚举
Vue.prototype.$OssUploader = OssUploader
