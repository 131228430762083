const list = [
  {
    label: '中文',
    value: '1'
  },
  {
    label: '英文',
    value: '2'
  }
]

export default list
